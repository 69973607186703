.container {
  border: 1px solid #dddddd;
  border-radius: 8px;
}

.title-aswer {
  font-size: 16px;
  font-weight: bold;
  word-break: normal;
  color: black;
}
