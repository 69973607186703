.container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 210px;
  margin-bottom: 12px;
}

.title {
  color: rgb(98, 187, 70);
  font-size: 16px;
  font-weight: 600;
}

.subtitle {
  color: #999;
  font-size: 14px;
  font-weight: 600;
}

.value {
  color: black;
  font-size: 24px;
  font-weight: 600;
}
