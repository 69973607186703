body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.center-box{
  margin-left: auto;
  margin-right: auto;
}
.MuiButton-root{
  text-transform: uppercase;
}
.MuiTableRow-hover{
  cursor: pointer;
}
.tabpanel{width:100%}
.MuiAutocomplete-groupLabel{
  background-color: #f0f0f0;
  line-height: 1.2em;
  padding-top:5px;
  padding-bottom:5px;
}
.MuiFab-primary{
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.MuiLinearProgress-root{
  position: fixed;
  top:0;
  width: 100%;
}
.MuiButtonBase-root.Mui-disabled{
  color:#ddd;
}
.file-viewer{
  height: 100vh;
  border:0;
}
.file-viewer iframe{
  width:100%;
  height:100%;
}
.MuiLink-root{cursor: pointer}
.img-responsive{
  max-width: 100%;
  max-height: 100%;
}
.MuiDropzoneArea-root{
  border-color: #999 !important;
  border-radius: 8px;
}
.MuiDropzoneArea-root .MuiDropzoneArea-textContainer{
  margin-top:75px;
  color: #999;
}

.MuiDropzonePreviewList-imageContainer .MuiButtonBase-root{
  top: 40px;
  left:50%;
  margin-left: 70px;
}
.MuiDropzonePreviewList-imageContainer .MuiTypography-body1{
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color:#999;
}
.dropzone-component .MuiTypography-subtitle1{
  display: block;
  margin: .5em 0 1.5em;
}
.dropzone-component .MuiDropzonePreviewList-image{
  padding:15px
}

.semi-circle{
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  h4{
    margin:0;
    color:gray;
  }
  .graph-min, .graph-max{
    position: absolute;
    left:50%;
    line-height: 1;
    font-size: 12px;
    color: grey;
  }
  .graph-min{
    margin-left: -117px;
    min-width: 60px;
    text-align: right;
  }
  .graph-max {
    margin-left: 55px;
  }
  h2{margin: 0;}
  p{
    margin:0;
    color:grey;
    font-weight: bold;
    line-height: 1;
    font-size: 14px;
  }
}