.framed-box {
  border: 2px solid black;
  padding: 16px;
  border-radius: 8px;
}
.modal {
  position: fixed;
  width: 100%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #e9e3e3;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.modal-content {
  margin-bottom: 10px;
  overflow-y: auto;
  height: 100%;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-footer button {
  margin-left: 10px;
  margin-top: 1rem;
}

.fixed-height-div {
  height: 125mm;
  width: 100%;
}

.imgRelatorio {
  max-width: 120mm;
  padding-bottom: 3rem;
}

/* Estilos gerais */
.print-container {
  padding: 20px;
}

.title {
  width: 100%;
  height: 38px;
  background: #62bb46;
  margin-top: 40px;
  text-align: left;
  justify-content: center;
}

.grid-space-container {
  width: 70%;
  margin-top: 40px;
}

.item-response-outrasacoes {
  text-align: justify;
  border-bottom: 1px solid #e9e3e3;
  margin-top: 40px;
}
.item-response-center {
  text-align: center;
  border-bottom: 1px solid #e9e3e3;
  padding-right: 1rem;
}

.item-response-center-color {
  background: #62bb46;
  text-align: center;
  padding-right: 1rem;
}

/* Estilos para impressão */
@media print {
  @page {
    size: A4;
    margin: 1cm;
  }

  body {
    -webkit-print-color-adjust: exact;
    font-size: 12pt;
  }

  .print-container {
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
  }

  /* .print-container::before {
    content: url('https://potencializee.develop.pumpkintech.com.br/logo.png');
    display: block;
    position: fixed;
    top: 1cm; 
    left: 1cm; 
    width: 1cm; 
    height: auto;
    padding-botton: 2.5rem;
  } */

  /* .print-logo {
    position: fixed;
    //display: block;
    top: 10px;
    right: 10px;
    width: 100px;
    height: auto;
    //text-align: center;
    //padding: 20px 0;
    z-index: 1000;
  } */

  /*.print-logo img {
    max-width: 200px;
    height: auto;
  } */

  /* Adicionando espaçamento no topo da página para acomodar o logotipo */
  .print-container {
    padding-top: 2cm; /* Ajuste conforme o tamanho do logotipo */
  }

  .print-hidden {
    display: none !important;
  }
  .chart-container {
    max-width: 700px;
    max-height: none;
    min-height: 250px !important;
    overflow: visible;
  }

  .grid-space-container {
    width: 80%;
    margin-top: 40px;
  }

  .title-modal {
    width: 100%;
    height: 38px;
    background: #62bb46;
    margin-top: 40px;
    text-align: left;
    justify-content: center;
  }

  .item-response-center {
    text-align: center;
    border-bottom: 1px solid #e9e3e3;
    padding-right: 1rem;
  }

  .item-response-center-color {
    background: #62bb46;
    text-align: center;
    padding-right: 1rem;
  }

  /* .title, .title-modal, .title-comentarios-adicionais, .title-outras-acoes, 
  .title-capex, .title-capex-modal, .title-section, .title-section-center,
  .item-border, .item-response-justify, .item-response-outrasacoes,
  .item-response, .item-response-unico, .item-response-center,
  .item-response-right, .item-response-right-modal, .item-response-center-color,
  .item-response-left-color, .item-response-rigth-color,
  .grid-container, .grid-container-modal, .grid-container-step,
  .grid-container-modal-segmento, .grid-space-container {
    margin-top: 0; 
  }
  /* Remove margens superiores para impressão */

  /* .title {
      page-break-before: always; Força quebra de página antes do título
  }  */
}
.print-grid-container {
  margin-top: 10%;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.title-section-center {
  margin-top: 40px;
  text-align: center;
}

.title-section-center-rel {
  width: 100%;
  margin-top: 40%;
  text-align: center;
  color: #62bb46;
}
.title-section {
  width: 80%;
  margin-top: 40px;
  text-align: left;
}
.title {
  width: 100%;
  height: 38px;
  background: #62bb46;
  margin-top: 40px;
  text-align: left;
  justify-content: center;
}
.title-capex-modal {
  width: 100%;
  background: #62bb46;
  margin-top: 40px;
  text-align: center;
}

.title-capex-grid-detail {
  width: 100%;
  margin-top: 5px;
  text-align: center;
}

.item-response {
  text-align: left;
  border-bottom: 1px solid #e9e3e3;
  padding-right: 1rem;
}

.grid-container {
  width: 80%;
}
.page-break-before {
  page-break-before: always; /* Quebra de página antes do componente */
  margin-top: 20px; /* Espaço superior para separação visual */
}

.page-break-inside-avoid {
  page-break-inside: avoid;
}
