.container {
  width: 85%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
  margin-bottom: 25px;
}

.subContainer {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  width: 80%;
  border-radius: 25px;
  padding: 8px;
  z-index: 1;
}

.title {
  /* color: rgb(98, 187, 70); */
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  width: 50%;
  text-align: right;
}

.subtitle {
  /* color: #999; */
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  padding-left: 24px;
  margin-right: 8px;
}

.value {
  color: #466ace;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  /* text-shadow: 1px 1px 4px #5c5c5c; */
}

.value.below {
  color: rgb(243, 207, 6);
}

.triangle {
  display: block;
  position: absolute;
  left: 50%;
  bottom: -15px;
  width: 35px;
  height: 35px;
  rotate: 45deg;
  z-index: 0;
}
