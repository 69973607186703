.orcamentosColumn {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 8px;
  cursor: pointer;
}

.orcamentosText {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 18px;
  position: relative;
}

.tooltip {
  font-size: 12px;
}
